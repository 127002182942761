import fancybox from '@fancyapps/fancybox';

// FUNÇÕES/MÓDULOS SITE
export default $(function () {
    $('#scroll-effect a').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top }, 500, 'linear');
    });

    $("[data-fancybox]").fancybox({
        thumbs: {
            autoStart: false
        },
        buttons: [
            'download',
            'close'
        ]
    });

    /*$(window).scroll(function () {
        var altura = $(".content-product").height();
        var alturaduvida = $("#duvida").height();
        var alturatotal = altura - alturaduvida;
        var imgfloat = $(".img-float");
        var metade = altura / 2;
        var top = metade + $(".content-product")[0].clientHeight;
        console.log(altura);
        console.log(metade);
        console.log(top);
        console.log($(".content-product")[0].scrollTop);


        if ($(this).scrollTop() <= alturatotal) {
            imgfloat.css({
                'top': top
            });

            imgfloat.fadeIn();
        } else {
            imgfloat.fadeOut();
        };
    });*/


    var alturaViewport = $(window).height();
    var alturaElemento = $(".content-product").height();
    var distanciaTopo = $(".content-product").offset().top;
    var diferenca = alturaElemento - alturaViewport;

    var posicaoScroll = $(window).scrollTop();
    var bottom = (distanciaTopo + diferenca) - posicaoScroll + 250;
    console.log(bottom);

    $(".img-float").css({
        'bottom': bottom
    });

    $(window).scroll(function () {

        var posicaoScroll = $(window).scrollTop();
        var bottom = (distanciaTopo + diferenca) - posicaoScroll + 250;
        console.log(bottom);

        $(".img-float").css({
            'bottom': bottom
        });

    });

    /* Replace all SVG images with inline SVG*/
    jQuery('img.svg').each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function (data) {

            var $svg = jQuery(data).find('svg');

            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }

            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            $svg = $svg.removeAttr('xmlns:a');

            $img.replaceWith($svg);

        }, 'xml');

    });
});